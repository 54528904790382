import React from "react";
import "../../App.css";
import NavbarComp from "../navbar/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

class Contact extends React.Component {
  render() {
    return (
      <div className="container d-flex justify-content-center vh-100 mt-5">
        <div className="centered-box w-lg-75 w-100">
          <NavbarComp />
          <div className="row">
            <div className="mt-3" id="about">
              <h5>Contact Me</h5>
              <hr></hr>

              <div>
                <div>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12">
                          <p>
                            <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
                            &nbsp;{" "}
                            <a
                              href="mailto:chavansubhed@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#28CEAF",
                                textDecoration: "none",
                              }}
                            >
                              chavansubhed@gmail.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
