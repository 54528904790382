import React from "react";
import "../../App.css";
import NavbarComp from "../navbar/navbar";

class Experience extends React.Component {
  render() {
    return (
      <div className="container d-flex justify-content-center vh-100 mt-5">
        <div className="centered-box w-lg-75 w-100">
          <NavbarComp />
          <div className="row">
            <div className="mt-3" id="about">
              <h5>Experience</h5>
              <hr></hr>

              <div>
                <div>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-lg-3 col-12">
                          <div className="mb-4">
                            <img
                              src="https://interiorlogicgroup.com/wp-content/uploads/2018/12/ILG-Logo-CMYK.png"
                              style={{ height: "60px" }}
                              alt="ILG"
                            ></img>
                          </div>
                          <h6>
                            Interior Logic Group, Inc. <br></br>
                            <span
                              style={{ fontSize: ".8em", color: "#707070" }}
                            >
                              A Blackstone Company
                            </span>
                          </h6>
                          <p>Irvine, CA</p>
                          <p>Software Engineer</p>
                        </div>

                        <div className="col-lg-9 col-12">
                          <p>
                            <ul>
                              <li>
                                Designed and delivered an optimized
                                visualization platform (Viz 2.0) for Interior
                                Logic Group, responsible for DevOps and Backend.
                              </li>
                              <li>
                                Led the engineering team in planning, system
                                designing, and implementing the application in
                                SpringBoot, Flask, NodeJS, React, and AWS.
                              </li>
                              <li>
                                Developed cloud infrastructure and led the
                                DevOps team to build scaling architecture for
                                Windows Instances on AWS using automation and
                                the boto3 library, delivered a robust scaling
                                system with a custom scaling factor and
                                real-time unreal engine game server hosting.
                              </li>
                              <li>
                                Developed cloud infrastructure and led the
                                DevOps team to build scaling architecture for
                                Windows Instances on AWS using automation and
                                the boto3 library. Designed a robust scaling
                                system with a custom scaling factor and
                                real-time unreal engine game server hosting.
                              </li>
                              <li>
                                Collaborated with teams to deliver advancements
                                and solutions and assisted game engineers with
                                API for Unreal Engine and integration, wrote
                                Powershell scripts for Windows scaling
                                infrastructure, and designed and maintained the
                                application security and distribution using AWS
                                infrastructure.
                              </li>
                              <li>
                                Designed internal tools for the platform and
                                operations team and designed UI/UX by
                                understanding the requirements.
                              </li>
                              <li>
                                Reported weekly to the VP of Engineering on the
                                progress, documented the research, conducted
                                training sessions, contributed to the product
                                roadmap, requirements, and API code, and created
                                the demo environments.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item ">
                      <div className="row  mt-4">
                        <div className="col-lg-3 col-12">
                          <div className="mb-4">
                            <img
                              src="/images/company/curativly.png"
                              style={{ height: "60px" }}
                              alt="Curativly"
                            ></img>
                          </div>
                          <h6>Curativly, Inc.</h6>
                          <p>Chico, CA</p>
                          <p>Sr. Software Engineer</p>
                        </div>
                        <div className="col-lg-9 col-12">
                          <p>
                            <ul>
                              <li>
                                {" "}
                                Led the design and development of multiple
                                enterprise-level microservice applications,
                                driving $2 million of revenue every year using
                                the the latest technologies of AWS, Javascript,
                                MongoDB, React, Python, C++, Unreal Engine.
                              </li>
                              <li>
                                {" "}
                                Designed and implemented scalable APIs and
                                background workers for managing first- and
                                third-party proprietary licenses using Express,
                                AWS Lambda, and other AWS cloud technologies
                                that serve thousands of license requests daily.
                              </li>
                              <li>
                                {" "}
                                Led the development of several products E2E,
                                from identifying system requirements and partner
                                dependencies to workload balancing, software
                                implementation, engineering, testing, and
                                configuring metrics, alarms, monitors, and
                                dashboards.
                              </li>
                              <li>
                                {" "}
                                Reported directly to CEO: Engineering lead for
                                designing and developing the fintech transaction
                                dashboard that provides a rich visual summary of
                                daily user purchase patterns used by the board
                                of directors in the decision-making process.
                              </li>
                              <li>
                                {" "}
                                Enriched system metrics by integrating the
                                platforms with telemetry; facilitated in-depth
                                logging by correlating APIs with vector
                                contexts.
                              </li>
                              <li>
                                {" "}
                                Continuous Integration/Deployment Pipeline
                                Integration, pull requests, code reviews,
                                load/stress testing, unit/integration/e2e
                                testing.
                              </li>
                              <li>
                                Led the development team to launch the
                                application on time with 6+ constraints,
                                ensuring adherence to the highest quality
                                standards and meeting customer requirements.
                              </li>
                              <li>
                                Developed a web scraping program in Python to
                                help the company download public data, including
                                over 5,000 company descriptions and stock
                                quotes, enriching internal data and increasing
                                research efficiency by over 50%
                              </li>
                              <li>
                                Determined areas of improvement by regularly
                                monitoring existing business systems, boosting
                                business efficiency by at least 10 to 15% every
                                year through automation of repetitive tasks.
                              </li>
                              <li>
                                Promoted within 6 months due to solid
                                performance and organizational impact.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item ">
                      <div className="row  mt-4">
                        <div className="col-lg-3 col-12">
                          <div className="mb-4">
                            <img
                              src="https://studycalifornia.us/wp-content/uploads/2021/02/CSUCHICO-Seal-Color-ISS-Sig-300dpi-6.2018-Steve-Wildhaber-Jr..png"
                              style={{ height: "60px" }}
                              alt="Chico State"
                            ></img>
                          </div>
                          <h6>California State University, Chico</h6>
                          <p>Chico, CA</p>
                          <p>Graduate Research Assistant</p>
                        </div>
                        <div className="col-lg-9 col-12">
                          <p>
                            <ul>
                              <li>
                                {" "}
                                Working with Dr. Kevin Buffardi and Chico State
                                Enterprise, funded by California Governor's
                                Office of Planning and Research - Learning Lab.
                              </li>
                              <li>
                                {" "}
                                Published paper titled Is Programming Relevant
                                to CS1 Students’ Interest?
                              </li>
                              <li>
                                {" "}
                                Core developer for Codewit.us: A Platform for
                                Diverse Perspectives in Coding, built based on
                                research.
                              </li>
                              <li>
                                {" "}
                                Implemented an online exercise-based programming
                                portal using Rails and MySQL.
                              </li>
                              <li>
                                {" "}
                                Created various project-specific valuable assets
                                such as knowledge transfer documents, test
                                plans, defect summary reports, and lessons
                                learned documents, successfully delivering 3
                                releases involving UAT and regression testing.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Experience;
