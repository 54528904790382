import React from "react";
import "../../App.css";
import NavbarComp from "../navbar/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faAward } from "@fortawesome/free-solid-svg-icons";

class Awards extends React.Component {
  render() {
    return (
      <div className="container d-flex justify-content-center vh-100 mt-5">
        <div className="centered-box w-lg-75 w-100">
          <NavbarComp />
          <div className="row">
            <div className="mt-3" id="about">
              <h5>Awards</h5>
              <hr></hr>

              <div>
                <div>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>
                            <FontAwesomeIcon
                              icon={faTrophy}
                              style={{
                                color: "goldenrod",
                                fontSize: "0.8em",
                                marginRight: "0.5em",
                              }}
                            />
                            Med4Dev India - Israel International Hackathon
                          </h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                Designed and developed an online crowdfunding
                                platform for cancer patients.
                              </li>
                              <li>Planned and developed a POC in 36 hours.</li>
                              <li>
                                Declared winner for building breakthrough
                                affordable tech solutions for India Read more
                                at:{" "}
                                <a
                                  href="https://yourstory.com/2016/07/med4dev-hackathon"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  YourStory
                                </a>
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>
                            <FontAwesomeIcon
                              icon={faTrophy}
                              style={{
                                color: "goldenrod",
                                fontSize: "0.8em",
                                marginRight: "0.5em",
                              }}
                            />
                            ByteCamp'17 SIES GST Annual Hackathon - 2017{" "}
                          </h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>Awarded for most innovative project.</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>
                            <FontAwesomeIcon
                              icon={faTrophy}
                              style={{
                                color: "goldenrod",
                                fontSize: "0.8em",
                                marginRight: "0.5em",
                              }}
                            />
                            Maharashtra State Board of Technical Education
                            (MSBTE) Regional Level Project Competiton - 2016
                          </h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                Developed wireless rocket launching system POC.
                              </li>
                              <li>
                                The project included real-time data streaming
                                from rocket carrying 11gms payload consisting of
                                sensors, battery and transmission/receiver
                                module.{" "}
                              </li>
                              <li>
                                Attempted to develop rocket fuel and propulsion
                                system.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>
                            <FontAwesomeIcon
                              icon={faAward}
                              style={{
                                color: "goldenrod",
                                fontSize: "0.8em",
                                marginRight: "0.5em",
                              }}
                            />
                            RoboGalaxy National Level Robotics Competiton - 2013
                          </h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>Youngest team to qualify.</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Awards;
