import React from 'react';
import '../../App.css';
import NavbarComp from '../navbar/navbar';
import CodeBlockDefaultExample from './codeBlock.js'
class Research extends React.Component {
   render() {
      return (
    <div className="container d-flex justify-content-center vh-100 mt-5">
      <div className="centered-box w-lg-75 w-100">

        <NavbarComp />
        <div className='row'>
   
            <div className='mt-3' id='about'>
                <h5>Research</h5>
                <hr></hr>

                <div>
                    <div>
                        <ul className="list-group">
                        <li className="list-group-item">
                            <div className='row mt-4'>
                                <div className='col-12 mb-3'>
                                    <h4>Is Programming Relevant to CS1 Students’ Interests?</h4>
                                </div>

                                <div className='col-12' >
                                    <p>
                                        <ul>
                                        <li>Authors: Kevin Buffardi and Subhed Chavan</li>
                                        <li>The full paper is available via <a href='https://dl.acm.org/doi/10.5555/3512469.3512475' rel="noreferrer"  target='_blank'>ACM Digital library.</a></li>
                                        <li>To cite this paper, use the following bibliography:
                                            Kevin Buffardi and Subhed Chavan. 2021. Is programming relevant to CS1 students’ interests? J. Comput. Sci. Coll. 37, 1 (October 2021), 45–53.
                                        </li>
                                        <li>
                                            Or import the following BibTeX reference:
                                            <div>
                                                <CodeBlockDefaultExample />
                                            </div>
                                        </li>
                                        
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </li>
                           <hr></hr> 
                        <li className="list-group-item">
                            <div className='row mt-4'>
                                <div className='col-12 mb-3'>
                                    <h4>Remote Sensing Technique for Monitoring and Reducing Harmful Gas Emission From Vehicles</h4>
                                </div>

                                <div className='col-12' >
                                    <p>
                                        <ul>
                                        <li>Authors: Subhed Chavan, Sahil Shetty, Sidharth Chandran and Anindita Khade</li>
                                        <li>The full paper is available via IJRAR <a href='https://www.ijrar.org/viewfull.php?&p_id=IJRAR19H1217' rel="noreferrer" target='_blank'>Paper Link</a></li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </li>    
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
      );
   }
}
export default Research;