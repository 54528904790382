import React from 'react';
import '../../App.css';
import NavbarComp from '../navbar/navbar';

class Blog extends React.Component {
   render() {
      return (
    <div className="container d-flex justify-content-center vh-100 mt-5">
      <div className="centered-box w-lg-75 w-100">

        <NavbarComp />
        <div className='row'>
   
            <div className='mt-3' id='about'>
                <h5>Blogs</h5>
                <hr></hr>

                <div>
                    <div>
                        <ul className="list-group">
                        <li className="list-group-item">
                            <div className='row mt-4'>
                                <div className='col-12 mb-3 text-center'>
                                    <h6>Coming Soon.</h6>
                                </div>

                                <div className='col-12' >
                                    <p>
                                 
                                    </p>
                                </div>
                            </div>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
      );
   }
}
export default Blog;