import React from 'react';

import { CodeBlock } from 'react-code-blocks';

const exampleCodeBlock = `@article{buffardi2021programming,
    title={Is programming relevant to CS1 students' interests?},
    author={Buffardi, Kevin and Chavan, Subhed},
    journal={Journal of Computing Sciences in Colleges},
    volume={37},
    number={1},
    pages={45--53},
    year={2021},
    publisher={Consortium for Computing Sciences in Colleges}
}`;

const CodeBlockDefaultExample = () => {
  return (
    <CodeBlock language="text" showLineNumbers={true} text={exampleCodeBlock} />
  );
};

export default CodeBlockDefaultExample;