import React from "react";
import { Navbar, Nav } from "react-bootstrap";

import "./Navbar.css";

const NavbarComp = () => {
  return (
    <>
      <a href="/" style={{ textDecoration: "none", color: "inherit" }}>
        <h1>Subhed Chavan</h1>
        <p>Software Engineer | Backend Developer | Full-Stack Developer</p>
      </a>
      <div
        style={{ width: "100%", backgroundColor: "black", height: "15px" }}
      ></div>

      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/experience">Experience</Nav.Link>
            <Nav.Link href="/projects">Projects</Nav.Link>
            <Nav.Link href="/research">Research</Nav.Link>
            <Nav.Link href="/awards">Awards</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
          <Nav className="m-auto"></Nav>
          <Nav className="mr-auto">
            {/* <Nav.Link href="/blog">Blog</Nav.Link> */}
            <Nav.Link href="/resume/Subhed-Chavan.pdf" target="_blank">
              Resume/CV
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavbarComp;
