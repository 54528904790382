import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/home/home'
import Experience from './components/experience/experience'
import Project from './components/project/project'
import Contact from './components/contact/contact'
import Research from './components/research/research'
import Awards from './components/awards/awards'
import Blog from './components/blog/blog'

function App() {
  return (
    <>
    <BrowserRouter>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/research" element={<Research />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/blog" element={<Blog />} />

       </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
