import React from "react";
import "../../App.css";
import NavbarComp from "../navbar/navbar";

class Project extends React.Component {
  render() {
    return (
      <div className="container d-flex justify-content-center vh-100 mt-5">
        <div className="centered-box w-lg-75 w-100">
          <NavbarComp />
          <div className="row">
            <div className="mt-3" id="about">
              <h5>Projects</h5>
              <hr></hr>

              <div>
                <div>
                  <ul className="list-group">
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>Chico State Pantry Application</h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                Initiated and developed the Chico State Pantry
                                Application, a food pantry management system
                                using REST API, NodeJS, and Express. The project
                                was later expanded by future batches of
                                CSCI-620, who built additional functionality and
                                improved upon the original design.
                              </li>
                              <li>
                                Applied software design patterns to create a
                                scalable and production-ready application with a
                                clean and maintainable codebase, ensuring
                                long-term sustainability and adaptability for
                                future enhancements.
                              </li>
                              <li>
                                Enabled the university to efficiently track food
                                inventory and its distribution to students,
                                optimizing resource management and ensuring
                                equitable access.
                              </li>
                              <li>
                                Integrated donation tracking from multiple
                                sources, enhancing transparency, accountability,
                                and real-time insights into contributions.
                              </li>
                              <li>
                                Implemented data visualization tools to help
                                administrators easily analyze and manage
                                donation and inventory records, leading to
                                better operational decision-making.
                              </li>
                              <li>
                                Technologies used: NodeJS, Express, MongoDB,
                                Bootstrap, jQuery.
                              </li>
                              <li>
                                Tools used: JSLint for code quality, Travis-CI
                                for continuous integration and deployment.
                              </li>
                              <li>
                                The project has made a lasting impact by
                                streamlining pantry management operations,
                                providing a foundation for future students to
                                continue building functionality, and maintaining
                                a clean, scalable design.
                              </li>
                              <li>
                                Code:{" "}
                                <a
                                  href="https://github.com/ChicoState/PantryNode"
                                  style={{ textDecoration: "none" }}
                                >
                                  GitHub
                                </a>
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>

                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>Recommendation App for Places and Activities</h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                Designed and developed a web application for
                                places and activities suggestion.
                              </li>
                              <li>
                                Developed poll mechanism for reviews and
                                comments.
                              </li>
                              <li>
                                Integrated 5+ API endpoint for gathering data.
                              </li>
                              <li>
                                Implemeted chat system using SocketIO with
                                additional features for users.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>Covid 19 Prediction using Machine Learning</h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                Used the Kaggle dataset to train a machine
                                learning model initially.
                              </li>
                              <li>
                                Developed a web application in Flask, to predict
                                the probability of having COVID-19.
                              </li>
                              <li>
                                With every search we add new data to the data
                                set and train model to get accurate results.
                              </li>
                              <li>
                                The application takes various parameters and
                                computes to predict the final result using
                                machine learning models.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>Wearable Tracker</h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                ToolsDeveloped a wearable device to track a
                                person and analyze crowd in the area.
                              </li>
                              <li>
                                ToolsThe aim was to design a tracker for
                                analyzing people's interest in Amusement and
                                theme park's ride and places.
                              </li>
                              <li>
                                ToolsUse the same tracker for locker and payment
                                purposes in the park.
                              </li>
                              <li>
                                ToolsThe tracker was also aimed to track
                                toddlers in the park.
                              </li>
                              <li>
                                ToolsTechnologies used: ESP 8266, Bluetooth,
                                RFID, NodeJS, and MongoDB for the web
                                application.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>
                            Realtime CO2 emission control device for vehicles
                          </h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                Designed and developed an IoT device for
                                monitoring a vehicle's pollution emission
                                information.
                              </li>
                              <li>
                                Automated manual process of PUC by gathering
                                information through sensors and sending it to
                                the government server through endpoints.
                              </li>
                              <li>
                                Implemented using Arduino, Raspberry Pi, Gas
                                Sensors, Web / Mobile Application.
                              </li>
                              <li>
                                Experimented with different pollution measures
                                for a higher accuracy rate.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="list-group-item">
                      <div className="row mt-4">
                        <div className="col-12 mb-3">
                          <h4>
                            Weather monitoring Model Rocket & Wireless Launching
                            Systems
                          </h4>
                        </div>

                        <div className="col-12">
                          <p>
                            <ul>
                              <li>
                                Designed a wireless rocket launching system and
                                base receiving station.
                              </li>
                              <li>
                                The project was designed as a model rocket to
                                gather and analyze various parameters such as
                                pressure, altitude, speed and temperature.
                              </li>
                              <li>
                                This model rocket demonstrated the transmission
                                of data from the model rocket and receiving at
                                the base station and visualizing it.
                              </li>
                              <li>
                                Experimented with different atmosphere measures
                                for higher accuracy.
                              </li>
                              <li>
                                Implemented using Arduino, Raspberry Pi,
                                Embedded Programming, PHP, MySQL, HTML, IoT.
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Project;
