import React from "react";
import "../../App.css";
import NavbarComp from "../navbar/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faGraduationCap,
  faMapPin,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

class Home extends React.Component {
  render() {
    return (
      <div className="container d-flex justify-content-center vh-100 mt-5">
        <div className="centered-box w-lg-75 w-100">
          <NavbarComp />

          <div className="row">
            <div className="col-lg-5 col-12 container  d-flex align-items-center">
              <img
                src="/images/subhed-photos/subhed6.jpg"
                alt="Subhed Chavan"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="col-lg-7 mt-lg-0 mt-3 col-12 container d-flex align-items-center">
              <div className="w-100">
                <p>
                  <FontAwesomeIcon
                    icon={faGithub}
                    size="lg"
                    style={{
                      marginRight: "0.5em",
                    }}
                  />{" "}
                  GitHub:{" "}
                  <a
                    href="https://github.com/subhed"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#28CEAF", textDecoration: "none" }}
                  >
                    @subhed
                  </a>
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    size="lg"
                    style={{ color: "#0077B5", marginRight: "0.5em" }}
                  />{" "}
                  LinkedIn:{" "}
                  <a
                    href="https://linkedin.com/in/subhed"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#28CEAF", textDecoration: "none" }}
                  >
                    /in/subhed
                  </a>
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    size="lg"
                    style={{
                      marginRight: "0.5em",
                    }}
                  />{" "}
                  Twitter:{" "}
                  <a
                    href="https://twitter.com/subhed"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#28CEAF", textDecoration: "none" }}
                  >
                    @subhed
                  </a>
                </p>

                <hr></hr>

                <p>
                  <FontAwesomeIcon
                    icon={faUserTie}
                    size="lg"
                    style={{
                      marginRight: "0.5em",
                    }}
                  />{" "}
                  3+ years of professional experience
                </p>

                <p>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    size="lg"
                    style={{
                      marginRight: "0.1em",
                    }}
                  />{" "}
                  MS in Computer Science - California State University, Chico
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faMapPin}
                    size="lg"
                    style={{
                      marginRight: "0.7em",
                    }}
                  />{" "}
                  Chico, CA
                </p>
              </div>
            </div>

            <div className="mt-3" id="about">
              <h5>About</h5>
              <hr></hr>

              <p>
                I am a Software Engineer at Interior Logic Group, Inc. - A
                Blackstone Company, Currently leading visualization software
                team for version 2.0 and developing a platform for builders,
                curators, and potential home buyers that incorporates real-time
                rendering and a price engine—now focusing on the nexus of Web
                Application, Cloud, and Unreal Engine.
              </p>
              <p>
                With 3+ years in Software Engineering and Game Development
                Support, I specialize in writing Backend API and Unreal Engine 5
                plugins, adept at plugin development and production pipelines
                for Gaming and Visualization. I excel in Rapid Prototyping,
                crafting Proof of Concept (POC) and Minimum Viable Product (MVP)
                for projects. My role includes building cutting-edge software,
                developing internal tools, DevOps, Geo-Distributed Clouds and
                collaborating with artists for enhanced visual quality and
                performance.
              </p>
              <p>
                <b>Areas of Expertise:</b> NodeJS, ReactJS, Python (Includes:
                Automation, Flask, Geo-Distributed Clouds and Django), JAVA,
                SpringBoot, AWS, Boto3, Unreal Engine APIs, 3D Visualization
              </p>

              <p>Get in touch with me for conversation or any information.</p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} size="lg" /> &nbsp;{" "}
                <a
                  href="mailto:chavansubhed@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#28CEAF", textDecoration: "none" }}
                >
                  chavansubhed@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
